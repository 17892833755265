import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { heading, title, metaDescription, metaKeywords } from 'constants/meta';
import MainTemplate from 'components/templates/MainTemplate';
import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';
import Delimiter from 'components/atoms/Delimiter';
import SendMessageToOffice from 'components/organisms/SendMessageToOffice';

const Title = styled.h1`
  font-weight: normal;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-direction: row;
    margin: 0 -0.5em;
  }
`;

const Col = styled.div`
  flex: none;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    padding: 0 0.5em;
    width: ${({ width }) => width}%;
  }
`;

const MessageButton = styled(Button)`
  margin-top: 2em;
`;

const ContactsPage = () => {
  const [isMessageModalShown, setMessageModalState] = useState(false);

  function handleMessageModalShow() {
    setMessageModalState(true);
  }

  function handleMessageModalHide() {
    setMessageModalState(false);
  }

  return (
    <MainTemplate>
      <Helmet>
        <title>{title.contacts}</title>
        <meta name="keywords" content={metaKeywords.contacts} />
        <meta name="description" content={metaDescription.contacts} />
      </Helmet>
      <SendMessageToOffice
        title={'Сообщение для "Золотая Камея"'}
        isModalShown={isMessageModalShown}
        onModalHide={handleMessageModalHide}
        messageRequired
      />
      <Title>{heading.contacts}</Title>
      <Row>
        <Col width={40}>
          <h2>Центральный офис и склад</h2>
          <p>
            <strong>Адрес:</strong>
            <br />
            420202, Республика Татарстан, г. Казань, ул. Чернышевского, д. 43/2, офис 424;
          </p>
          <p>
            <strong>Телефон:</strong>
            <br />
            8 800-700-1-789
            <br />
            +7 962-550-46-22
          </p>
          <p>
            <strong>Skype:</strong>
            <br />
            <Link href="skype:goldkamea?call">goldkamea</Link>
          </p>
          <p>
            <strong>E-mail:</strong>
            <br />
            <Link href="mailto:info@gold-kamea.ru">info@gold-kamea.ru</Link>
          </p>
          <p>
            <strong>Режим работы:</strong>
            <br />С понедельника по четверг — 9.00-16.00;
            <br />в пятницу — 9.00-15.00;
            <br />Суббота-воскресенье — выходной
          </p>
          <p>
            <strong>С Вами работают:</strong>
            <br />
            <Link to="/contacts/team">наши сотрудники</Link>
          </p>
          <MessageButton primary onClick={handleMessageModalShow}>
            Написать нам
          </MessageButton>
        </Col>
        <Col width={60}>
          <iframe
            title="Карта"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A780da81cb036a9df5a59d187fd25a8c8437d08fa9524326a838b6f505c4457e6&amp;source=constructor"
            width="100%"
            height="500"
            frameBorder="1"
            allowFullScreen
          />
        </Col>
      </Row>
      <Delimiter />
      <h2>Представители в регионах</h2>
      <p>
        <Link to="/contacts/predstaviteli-v-regionah">
          Контакты наших представителей в регионах
        </Link>
      </p>
      <Delimiter />
      <h2>Реквизиты компании &quot;Золотая Камея&quot;</h2>
      <p>
        <strong>Полное наименование:</strong> Индивидуальный предприниматель Никитина Гульфия
        Габдулловна
      </p>
      <p>
        <strong>Сокращенное наименование:</strong> ИП Никитина Г.Г.
      </p>
      <p>
        <strong>ОГРН:</strong> 316169000058072
      </p>
      <p>
        <strong>ИНН:</strong> 165803104021
      </p>
      <p>
        <strong>ОКВЭД:</strong> 68.20.2
      </p>
      <p>
        <strong>Юридический адрес:</strong> РТ, Зеленодольский район, с. Ореховка, ул. Кооперативная, д.5
      </p>
      <p>
        <strong>Почтовый адрес:</strong> 420202, Республика Татарстан, г. Казань, ул. Чернышевского, д. 43/2, офис 424
      </p>
      <p>
        <strong>Расчетный счет:</strong> 40802810062000049977
      </p>
      <p>
        <strong>Банк:</strong> ОТДЕЛЕНИЕ &quot;БАНК ТАТАРСТАН&quot; №8610 ПАО СБЕРБАНК
      </p>
      <p>
        <strong>БИК:</strong> 049205603
      </p>
      <p>
        <strong>Корреспондентский счет:</strong> 30101810600000000603
      </p>
    </MainTemplate>
  );
};

export default ContactsPage;
